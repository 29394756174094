var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.myCompanyPlan !== 6
    ? _c(
        "div",
        [
          _c(
            "a-drawer",
            {
              attrs: {
                title: "フィルター",
                placement: "right",
                closable: true,
                visible: _vm.drawerVisible,
                width: 520,
                wrapClassName: _vm.$style.filterDrawer
              },
              on: { close: _vm.drawerClose }
            },
            [
              _c("h2", [_vm._v("データの取得範囲")]),
              _c("a-config-provider", { attrs: { locale: _vm.locale } }, [
                _c(
                  "p",
                  { class: _vm.$style.filterLabel },
                  [
                    _vm._v("\n        対象期間:"),
                    _c("br"),
                    _c("a-date-picker", {
                      attrs: { value: _vm.dateStart },
                      on: { change: _vm.changeDateStart }
                    }),
                    _vm._v(" 〜 "),
                    _c("a-date-picker", {
                      staticStyle: { "margin-right": "20px" },
                      attrs: { value: _vm.dateEnd },
                      on: { change: _vm.changeDateEnd }
                    }),
                    !_vm.dateRangeValid
                      ? _c("span", [
                          _c("br"),
                          _vm._v("対象期間は1年以内に設定してください。")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm.dateRangeValid
                ? _c(
                    "a-button",
                    {
                      class: _vm.$style.filledBtn,
                      attrs: { loading: _vm.loading },
                      on: { click: _vm.dataUpdate }
                    },
                    [_vm._v("再読み込み")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "p",
                    {
                      class: _vm.$style.description,
                      staticStyle: { margin: "38px 0 20px" }
                    },
                    [
                      _c("span", { class: _vm.$style.emNum }, [
                        _vm._v(_vm._s(_vm.driveDataTotal))
                      ]),
                      _vm._v(" 件の運行データがみつかりました。")
                    ]
                  )
                : _vm._e(),
              _c("hr", { staticStyle: { "margin-bottom": "30px" } }),
              _c("h2", [_vm._v("データの表示方法")]),
              _c(
                "p",
                { class: _vm.$style.filterLabel },
                [
                  _vm._v("\n      対象支店: "),
                  _c("br"),
                  _c(
                    "a-select",
                    {
                      style: "width: 100%",
                      attrs: {
                        "show-search": "",
                        value: _vm.targetBranchSelected,
                        "filter-option": _vm.filterOption
                      },
                      on: { change: _vm.targetBranchItemChange }
                    },
                    [
                      _c(
                        "a-select-option",
                        { key: "0", attrs: { value: "0", selected: "" } },
                        [_vm._v("\n          全て\n        ")]
                      ),
                      _vm._l(_vm.targetBranchOptions, function(item) {
                        return _c(
                          "a-select-option",
                          { key: item.id, attrs: { value: item.id } },
                          [
                            _vm._v(
                              "\n          " + _vm._s(item.name) + "\n        "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "p",
                { class: _vm.$style.filterLabel },
                [
                  _vm._v("\n      対象グループ: "),
                  _c("br"),
                  _c(
                    "a-select",
                    {
                      style: "width: 100%",
                      attrs: {
                        "show-search": "",
                        value: _vm.targetGroupSelected,
                        "filter-option": _vm.filterOption
                      },
                      on: { change: _vm.targetGroupItemChange }
                    },
                    [
                      _c(
                        "a-select-option",
                        { key: "0", attrs: { value: "0", selected: "" } },
                        [_vm._v("\n          指定しない\n        ")]
                      ),
                      _vm._l(_vm.targetGroupOptions, function(item) {
                        return _c(
                          "a-select-option",
                          { key: item.id, attrs: { value: item.id } },
                          [
                            _vm._v(
                              "\n          " + _vm._s(item.name) + "\n        "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _vm.myCompanyPlan !== 4
                ? _c(
                    "p",
                    { class: _vm.$style.filterLabel },
                    [
                      _vm._v("\n      データの単位: "),
                      _c("br"),
                      _c(
                        "a-radio-group",
                        { attrs: { value: _vm.target } },
                        [
                          _c(
                            "a-radio-button",
                            {
                              attrs: { value: "user" },
                              on: {
                                click: function($event) {
                                  return _vm.targetChange("user")
                                }
                              }
                            },
                            [_vm._v("\n          ユーザー\n        ")]
                          ),
                          _c(
                            "a-radio-button",
                            {
                              attrs: { value: "vehicle" },
                              on: {
                                click: function($event) {
                                  return _vm.targetChange("vehicle")
                                }
                              }
                            },
                            [_vm._v("\n          車両\n        ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "p",
                { class: _vm.$style.filterLabel },
                [
                  _vm._v("\n      閲覧対象: "),
                  _c("br"),
                  _c(
                    "a-select",
                    {
                      style: "width: 100%",
                      attrs: {
                        "show-search": "",
                        value: _vm.targetSelected,
                        "filter-option": _vm.filterOption
                      },
                      on: { change: _vm.targetItemChange }
                    },
                    [
                      _c(
                        "a-select-option",
                        { key: "0", attrs: { value: "0", selected: "" } },
                        [_vm._v("\n          全て\n        ")]
                      ),
                      _vm._l(_vm.targetOptions, function(item) {
                        return _c(
                          "a-select-option",
                          { key: item.id, attrs: { value: item.id } },
                          [
                            _vm._v(
                              "\n          " + _vm._s(item.name) + "\n        "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _vm.myUseAnalyze === 1
                ? _c(
                    "p",
                    { class: _vm.$style.filterLabel },
                    [
                      _vm._v("\n      対象とする道路: "),
                      _c("br"),
                      _c(
                        "a-radio-group",
                        {
                          attrs: { value: _vm.targetRoad },
                          on: { change: _vm.roadChange }
                        },
                        [
                          _c("a-radio-button", { attrs: { value: "all" } }, [
                            _vm._v("\n          全て\n        ")
                          ]),
                          _c(
                            "a-radio-button",
                            { attrs: { value: "general" } },
                            [_vm._v("\n          幹線道路\n        ")]
                          ),
                          _c("a-radio-button", { attrs: { value: "narrow" } }, [
                            _vm._v("\n          生活道路\n        ")
                          ])
                        ],
                        1
                      ),
                      _vm.myUseAnnounce === 1
                        ? _c("span", { class: _vm.$style.tip }, [
                            _c("br"),
                            _vm._v(
                              "※アナウンスの結果は対象の道路に関わらず全ての結果が出力されます。"
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "p",
                { class: _vm.$style.filterLabel },
                [
                  _vm._v("\n      区切り方: "),
                  _c("br"),
                  _c(
                    "a-radio-group",
                    {
                      attrs: { value: _vm.dateXAxis },
                      on: { change: _vm.dateXChange }
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "month" } }, [
                        _vm._v("月")
                      ]),
                      _c("a-radio-button", { attrs: { value: "week" } }, [
                        _vm._v("週")
                      ]),
                      _c("a-radio-button", { attrs: { value: "day" } }, [
                        _vm._v("日")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { class: _vm.$style.headerContainer }, [
            _c("h1", [_vm._v("遵守状況確認")]),
            _c(
              "div",
              { class: _vm.$style.topInfoButtonsContainer },
              [
                _c(
                  "a-button",
                  {
                    class: _vm.$style.topInfoButton,
                    on: { click: _vm.drawerShow }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-filter",
                      staticStyle: { "margin-right": "4px" }
                    }),
                    _vm._v("フィルター")
                  ]
                ),
                _c(
                  "a-button",
                  {
                    class: _vm.$style.topInfoButton,
                    on: { click: _vm.showDetailReport }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-file-text-o",
                      staticStyle: { "margin-right": "4px" }
                    }),
                    _vm._v("集計情報")
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", { class: _vm.$style.targetDescription }, [
            _c("span", { class: _vm.$style.label }, [_vm._v("対象期間:")]),
            _c("span", { class: _vm.$style.value }, [
              _vm._v(
                _vm._s(_vm._f("momentDate")(_vm.dateStart)) +
                  " 〜 " +
                  _vm._s(_vm._f("momentDate")(_vm.dateEnd))
              )
            ]),
            _c("div", { staticStyle: { display: "inline-block" } }, [
              _c("span", { class: [_vm.$style.value, _vm.$style.numValue] }, [
                _vm._v(_vm._s(_vm.driveDataTotal))
              ]),
              _c("span", { class: _vm.$style.label }, [
                _vm._v("件の運行データが見つかりました")
              ])
            ])
          ]),
          _c("div", { class: _vm.$style.targetDescription }, [
            _c("ul", [
              _vm.targetGroupSelected === "0"
                ? _c("li", [
                    _c("span", { class: _vm.$style.label }, [
                      _vm._v("対象支店:")
                    ]),
                    _c("span", { class: _vm.$style.value }, [
                      _vm._v(
                        _vm._s(
                          _vm.targetBranchSelected === "0"
                            ? "全て"
                            : _vm.targetBranchOptions.find(function(e) {
                                return e.id === _vm.targetBranchSelected
                              }).name
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.targetGroupSelected !== "0"
                ? _c("li", [
                    _c("span", { class: _vm.$style.label }, [
                      _vm._v("対象グループ:")
                    ]),
                    _c("span", { class: _vm.$style.value }, [
                      _vm._v(
                        _vm._s(
                          _vm.targetGroupSelected === "0"
                            ? "指定なし"
                            : _vm.targetGroupOptions[_vm.targetGroupSelected]
                                .name
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _c("li", [
                _c("span", { class: _vm.$style.label }, [
                  _vm._v("データの単位: ")
                ]),
                _c("span", { class: _vm.$style.value }, [
                  _vm._v(_vm._s(_vm.target === "user" ? "ユーザー" : "車両"))
                ])
              ]),
              _c("li", [
                _c("span", { class: _vm.$style.label }, [_vm._v("閲覧対象:")]),
                _c("span", { class: _vm.$style.value }, [
                  _vm._v(
                    _vm._s(
                      _vm.targetSelected === "0"
                        ? "全て"
                        : _vm.targetOptions.find(function(e) {
                            return e.id === _vm.targetSelected
                          }).name
                    )
                  )
                ])
              ]),
              _c("li", [
                _c("span", { class: _vm.$style.label }, [_vm._v("対象道路:")]),
                _c("span", { class: _vm.$style.value }, [
                  _vm._v(
                    _vm._s(
                      _vm.targetRoad === "all"
                        ? "全て"
                        : _vm.targetRoad === "general"
                        ? "幹線道路"
                        : "生活道路"
                    )
                  )
                ])
              ]),
              _c("li", [
                _c("span", { class: _vm.$style.label }, [_vm._v("区切り:")]),
                _c("span", { class: _vm.$style.value }, [
                  _vm._v(
                    _vm._s(
                      _vm.dateXAxis === "month"
                        ? "月"
                        : _vm.dateXAxis === "week"
                        ? "週"
                        : "日"
                    )
                  )
                ])
              ])
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "row",
              staticStyle: { "margin-top": "30px" },
              attrs: { id: "main-dashboard" }
            },
            [
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticClass: "text-center col-lg-12",
                      staticStyle: {
                        "margin-top": "50px",
                        "margin-bottom": "500px"
                      }
                    },
                    [
                      _c("section", { staticClass: "card" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-body",
                            staticStyle: { margin: "250px 0" }
                          },
                          [_c("a-spin", { attrs: { tip: "Loading..." } })],
                          1
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.myUseAnalyze === 1
                ? _c("info-card", {
                    attrs: {
                      title: "遵守率",
                      usePercentage: true,
                      numPercentage: _vm.displayComplianceRateTotal[2].toFixed(
                        2
                      ),
                      numViolation: _vm.displayComplianceRateTotal[0],
                      numRegulation: _vm.displayComplianceRateTotal[1]
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showRate(0, "遵守率")
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1
                ? _c("info-card", {
                    attrs: {
                      title: "法定速度遵守率",
                      usePercentage: true,
                      numPercentage: _vm.displayComplianceRateSpeed[2].toFixed(
                        2
                      ),
                      numViolation: _vm.displayComplianceRateSpeed[0],
                      numRegulation: _vm.displayComplianceRateSpeed[1]
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showRate(1, "法定速度遵守率")
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1
                ? _c("info-card", {
                    attrs: {
                      title: "一時停止遵守率",
                      usePercentage: true,
                      numPercentage: _vm.displayComplianceRateStop[2].toFixed(
                        2
                      ),
                      numViolation: _vm.displayComplianceRateStop[0],
                      numRegulation: _vm.displayComplianceRateStop[1]
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showRate(2, "一時停止遵守率")
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1
                ? _c("info-card", {
                    attrs: {
                      title: "通行禁止遵守率",
                      usePercentage: true,
                      numPercentage: _vm.displayComplianceRateOneway[2].toFixed(
                        2
                      ),
                      numViolation: _vm.displayComplianceRateOneway[0],
                      numRegulation: _vm.displayComplianceRateOneway[1]
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showRate(3, "通行禁止遵守率")
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1
                ? _c("info-card", {
                    attrs: {
                      title: "踏切停止遵守率",
                      usePercentage: true,
                      numPercentage: _vm.displayComplianceRateRailway[2].toFixed(
                        2
                      ),
                      numViolation: _vm.displayComplianceRateRailway[0],
                      numRegulation: _vm.displayComplianceRateRailway[1]
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showRate(4, "踏切停止遵守率")
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1 &&
              _vm.targetSelected === "0" &&
              _vm.targetBranchSelected === "0" &&
              _vm.targetGroupSelected === "0"
                ? _c("rank-card", {
                    attrs: {
                      title: "遵守率(支店)",
                      rankData: _vm.displayRankingBranch
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0, 3),
                      isOrderDesc: true,
                      linkTo: "branch"
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        _vm.showRanking(
                          _vm.displayRankingBranch,
                          "遵守率(支店): ベスト",
                          "branch"
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1 &&
              _vm.targetSelected === "0" &&
              _vm.targetBranchSelected === "0" &&
              _vm.targetGroupSelected === "0"
                ? _c("rank-card", {
                    attrs: {
                      title: "遵守率(支店)",
                      rankData: _vm.displayRankingBranch
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0)
                        .reverse()
                        .slice(0, 3),
                      isOrderDesc: false,
                      linkTo: "branch"
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        _vm.showRanking(
                          _vm.displayRankingBranch.slice(0).reverse(),
                          "遵守率(支店): ワースト",
                          "branch"
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1 && _vm.targetSelected === "0"
                ? _c("rank-card", {
                    attrs: {
                      title: "遵守率",
                      rankData: _vm.displayRankingTarget
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0, 3),
                      isOrderDesc: true,
                      linkTo: _vm.target
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showRanking(
                          _vm.displayRankingTarget,
                          "遵守率: ベスト",
                          _vm.target
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1 && _vm.targetSelected === "0"
                ? _c("rank-card", {
                    attrs: {
                      title: "遵守率",
                      rankData: _vm.displayRankingTarget
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0)
                        .reverse()
                        .slice(0, 3),
                      isOrderDesc: false,
                      linkTo: _vm.target
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        _vm.showRanking(
                          _vm.displayRankingTarget.slice(0).reverse(),
                          "遵守率: ワースト",
                          _vm.target
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnnounce === 1
                ? _c("info-card", {
                    attrs: {
                      title: "アナウンス遵守率",
                      usePercentage: true,
                      numPercentage: _vm.displayComplianceRateAnnounce[2].toFixed(
                        2
                      ),
                      numViolation: _vm.displayComplianceRateAnnounce[0],
                      numRegulation: _vm.displayComplianceRateAnnounce[1],
                      useAnnounceIconInHeader: true
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showRate(
                          9,
                          "アナウンス遵守率",
                          "アナウンス数"
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnnounce === 1
                ? _c("info-card", {
                    attrs: {
                      title: "一時停止遵守率",
                      usePercentage: true,
                      numPercentage: _vm.displayComplianceRateStopAnnounce[2].toFixed(
                        2
                      ),
                      numViolation: _vm.displayComplianceRateStopAnnounce[0],
                      numRegulation: _vm.displayComplianceRateStopAnnounce[1],
                      useAnnounceIconInHeader: true
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showRate(
                          5,
                          "アナウンス一時停止遵守率",
                          "アナウンス数"
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnnounce === 1
                ? _c("info-card", {
                    attrs: {
                      title: "踏切停止遵守率",
                      usePercentage: true,
                      numPercentage: _vm.displayComplianceRateRailwayAnnounce[2].toFixed(
                        2
                      ),
                      numViolation: _vm.displayComplianceRateRailwayAnnounce[0],
                      numRegulation:
                        _vm.displayComplianceRateRailwayAnnounce[1],
                      useAnnounceIconInHeader: true
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showRate(
                          6,
                          "アナウンス踏切停止遵守率",
                          "アナウンス数"
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnnounce === 1
                ? _c("info-card", {
                    attrs: {
                      title: "ゾーン30速度超過",
                      useCount: true,
                      numCount: _vm.displayZone30SpeedAnnounce,
                      useAnnounceIconInHeader: true
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showCount(7, "速度超過", "アナウンス数")
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnnounce === 1
                ? _c("info-card", {
                    attrs: {
                      title: "速度超過",
                      useCount: true,
                      numCount: _vm.displaySpeedAnnounce,
                      useAnnounceIconInHeader: true
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showCount(8, "速度超過", "アナウンス数")
                      }
                    }
                  })
                : _vm._e(),
              _vm.targetSelected === "0" &&
              _vm.targetBranchSelected === "0" &&
              _vm.targetGroupSelected === "0" &&
              _vm.myUseAnnounce === 1
                ? _c("rank-card", {
                    attrs: {
                      title: "遵守率(支店)",
                      rankData: _vm.displayRankingBranchAnnounce
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0, 3),
                      isOrderDesc: true,
                      useAnnounceIconInHeader: true,
                      linkTo: "branch"
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        _vm.showRanking(
                          _vm.displayRankingBranchAnnounce,
                          "アナウンス遵守率(支店): ベスト",
                          "branch"
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.targetSelected === "0" &&
              _vm.targetBranchSelected === "0" &&
              _vm.targetGroupSelected === "0" &&
              _vm.myUseAnnounce === 1
                ? _c("rank-card", {
                    attrs: {
                      title: "遵守率(支店)",
                      rankData: _vm.displayRankingBranchAnnounce
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0)
                        .reverse()
                        .slice(0, 3),
                      isOrderDesc: false,
                      useAnnounceIconInHeader: true,
                      linkTo: "branch"
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        _vm.showRanking(
                          _vm.displayRankingBranchAnnounce.slice(0).reverse(),
                          "アナウンス遵守率(支店): ワースト",
                          "branch"
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.targetSelected === "0" && _vm.myUseAnnounce === 1
                ? _c("rank-card", {
                    attrs: {
                      title: "遵守率",
                      rankData: _vm.displayRankingTargetAnnounce
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0, 3),
                      isOrderDesc: true,
                      useAnnounceIconInHeader: true,
                      linkTo: _vm.target
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        return _vm.showRanking(
                          _vm.displayRankingTargetAnnounce,
                          "アナウンス遵守率: ベスト",
                          _vm.target
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.targetSelected === "0" && _vm.myUseAnnounce === 1
                ? _c("rank-card", {
                    attrs: {
                      title: "遵守率",
                      rankData: _vm.displayRankingTargetAnnounce
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0)
                        .reverse()
                        .slice(0, 3),
                      isOrderDesc: false,
                      useAnnounceIconInHeader: true,
                      linkTo: _vm.target
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        _vm.showRanking(
                          _vm.displayRankingTargetAnnounce.slice(0).reverse(),
                          "アナウンス遵守率: ワースト",
                          _vm.target
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1 && _vm.targetSelected === "0"
                ? _c("rank-card", {
                    attrs: {
                      title: "法定速度遵守率",
                      rankData: _vm.displayRankingTargetSpeed
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0)
                        .reverse()
                        .slice(0, 3),
                      isOrderDesc: false,
                      linkTo: _vm.target
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        _vm.showRanking(
                          _vm.displayRankingTargetSpeed.slice(0).reverse(),
                          "遵守率: ワースト",
                          _vm.target
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1 && _vm.targetSelected === "0"
                ? _c("rank-card", {
                    attrs: {
                      title: "一時停止遵守率",
                      rankData: _vm.displayRankingTargetStop
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0)
                        .reverse()
                        .slice(0, 3),
                      isOrderDesc: false,
                      linkTo: _vm.target
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        _vm.showRanking(
                          _vm.displayRankingTargetStop.slice(0).reverse(),
                          "遵守率: ワースト",
                          _vm.target
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1 && _vm.targetSelected === "0"
                ? _c("rank-card", {
                    attrs: {
                      title: "通行禁止遵守率",
                      rankData: _vm.displayRankingTargetOneway
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0)
                        .reverse()
                        .slice(0, 3),
                      isOrderDesc: false,
                      linkTo: _vm.target
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        _vm.showRanking(
                          _vm.displayRankingTargetOneway.slice(0).reverse(),
                          "遵守率: ワースト",
                          _vm.target
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.myUseAnalyze === 1 && _vm.targetSelected === "0"
                ? _c("rank-card", {
                    attrs: {
                      title: "踏切停止遵守率",
                      rankData: _vm.displayRankingTargetRailway
                        .filter(function(e) {
                          return e.regulationNum !== 0
                        })
                        .slice(0)
                        .reverse()
                        .slice(0, 3),
                      isOrderDesc: false,
                      linkTo: _vm.target
                    },
                    on: {
                      onClickSeeMore: function($event) {
                        _vm.showRanking(
                          _vm.displayRankingTargetRailway.slice(0).reverse(),
                          "遵守率: ワースト",
                          _vm.target
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm.targetSelected !== "0"
                ? _c(
                    "div",
                    { staticClass: "col-sm-6 col-md-4 col-lg-3 only-print" },
                    [_c("br")]
                  )
                : _vm._e(),
              _vm.myUseAnalyze === 1
                ? _c("div", { staticClass: "col-sm-6 col-md-4 col-lg-3" }, [
                    _c("section", { staticClass: "card" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "card-body" }, [
                        _c(
                          "div",
                          [
                            _c("vue-c3", {
                              staticClass: "height-300",
                              attrs: { handler: _vm.pieViolation }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.myUseAnalyze === 1
                ? _c("div", { staticClass: "col-sm-6 col-md-4 col-lg-3" }, [
                    _c("section", { staticClass: "card" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "card-body" }, [
                        _c(
                          "div",
                          [
                            _c("vue-c3", {
                              staticClass: "height-300",
                              attrs: { handler: _vm.pieOverSpeed }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.myUseAnalyze === 1
                ? _c("div", { staticClass: "col-sm-6" }, [
                    _c("section", { staticClass: "card" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        {
                          staticClass: "card-body",
                          staticStyle: { "padding-top": "0" }
                        },
                        [
                          _c("vue-c3", {
                            staticClass: "height-300",
                            attrs: { handler: _vm.lineComplianceRate }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.myUseAnalyze === 1
                ? _c("div", { staticClass: "col-sm-6" }, [
                    _c("section", { staticClass: "card" }, [
                      _vm._m(3),
                      _c(
                        "div",
                        {
                          staticClass: "card-body",
                          staticStyle: { "padding-top": "0" }
                        },
                        [
                          _c("vue-c3", {
                            staticClass: "height-300",
                            attrs: { handler: _vm.zoomViolation }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                title: _vm.rankingTitle,
                visible: _vm.visibleRanking,
                wrapClassName: _vm.$style.rankingModal,
                footer: null
              },
              on: { cancel: _vm.modalCancelRanking }
            },
            [
              _c("div", [
                _c(
                  "table",
                  {
                    staticClass: "table table-borderless",
                    class: _vm.$style.newTable
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th"),
                        _c("th", [_vm._v("違反件数")]),
                        _c("th", [_vm._v("規制件数")]),
                        _c("th", [_vm._v("遵守率")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(
                        _vm.rankingData.filter(function(e) {
                          return e.regulationNum !== 0
                        }),
                        function(t, i) {
                          return _c(
                            "tr",
                            { key: i, class: _vm.$style["stripe" + (i % 2)] },
                            [
                              _c(
                                "td",
                                { staticClass: "text-left text-nowrap" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name:
                                            _vm.rankingLinkTo == "branch"
                                              ? "branchShow"
                                              : _vm.rankingLinkTo == "vehicle"
                                              ? "vehicleShow"
                                              : _vm.rankingLinkTo == "user"
                                              ? "userShow"
                                              : "",
                                          params: { id: t.id }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(i + 1) +
                                          ": " +
                                          _vm._s(t.name) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("td", [_vm._v(_vm._s(t.violationNum))]),
                              _c("td", [_vm._v(_vm._s(t.regulationNum))]),
                              _c(
                                "td",
                                {
                                  class: [
                                    _vm.$style.tableValue,
                                    _vm.$style[_vm.textColor(t.complianceRate)]
                                  ]
                                },
                                [
                                  _vm._v(
                                    _vm._s(t.complianceRate.toFixed(2)) + " %"
                                  )
                                ]
                              )
                            ]
                          )
                        }
                      ),
                      0
                    )
                  ]
                ),
                _vm.rankingData.filter(function(e) {
                  return e.regulationNum === 0
                }).length > 0
                  ? _c("div", [
                      _c("div", { class: _vm.$style.rankingDataDesc }, [
                        _vm._v("運行データ 0 件")
                      ]),
                      _c(
                        "table",
                        { staticClass: "table table-borderless ranking" },
                        [
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.rankingData.filter(function(e) {
                                return e.regulationNum === 0
                              }),
                              function(t, i) {
                                return _c("tr", { key: i }, [
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(t.name))
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-right value" },
                                    [_c("strong", [_vm._v(" - ")])]
                                  )
                                ])
                              }
                            ),
                            0
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "a-button",
                {
                  class: _vm.$style.closeBtn,
                  on: { click: _vm.modalCancelRanking }
                },
                [_vm._v("\n        閉じる\n    ")]
              )
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                title: _vm.rateTitle,
                visible: _vm.visibleRate,
                wrapClassName: _vm.$style.rateModal,
                footer: null
              },
              on: { cancel: _vm.modalCancelRate }
            },
            [
              _c("div", [
                _vm.displayDateData[0]
                  ? _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm._f("momentYear")(_vm.displayDateData[0].date)
                        ) + " 年"
                      )
                    ])
                  : _vm._e(),
                _c(
                  "table",
                  {
                    staticClass: "table table-borderless",
                    class: _vm.$style.newTable
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("日付")]),
                        _c("th", [_vm._v("違反件数")]),
                        _c("th", [_vm._v(_vm._s(_vm.regulationName))]),
                        _c("th", [_vm._v("遵守率")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.displayDateData, function(t, i) {
                        return _c(
                          "tr",
                          { key: i, class: _vm.$style["stripe" + (i % 2)] },
                          [
                            _vm.dateXAxis == "month"
                              ? _c("td", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm._f("momentMonth")(t.date)) +
                                      " 月\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm.dateXAxis == "week"
                              ? _c("td", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("momentWeekStart")(t.date)
                                      ) +
                                      " 〜 " +
                                      _vm._s(_vm._f("momentWeekEnd")(t.date)) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm.dateXAxis == "day"
                              ? _c("td", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm._f("momentMonth")(t.date)) +
                                      "月 " +
                                      _vm._s(_vm._f("momentDay")(t.date)) +
                                      " 日\n            "
                                  )
                                ])
                              : _vm._e(),
                            _c("td", [_vm._v(_vm._s(t.violationNum))]),
                            _c("td", [_vm._v(_vm._s(t.regulationNum))]),
                            _c(
                              "td",
                              {
                                class: [
                                  _vm.$style.tableValue,
                                  _vm.$style[_vm.textColor(t.complianceRate)]
                                ]
                              },
                              [
                                _vm._v(
                                  _vm._s(t.complianceRate.toFixed(2)) + " %"
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm.dateXAxis == "month"
                  ? _c("div", [
                      _c(
                        "p",
                        { staticClass: "mb-0", class: _vm.$style.monthTips },
                        [
                          _vm._v(
                            "※ " +
                              _vm._s(_vm._f("momentMonth")(_vm.dateStart)) +
                              " 月は " +
                              _vm._s(_vm._f("momentWeekStart")(_vm.dateStart)) +
                              " からのデータです。"
                          )
                        ]
                      ),
                      _c("p", { class: _vm.$style.monthTips }, [
                        _vm._v(
                          "※ " +
                            _vm._s(_vm._f("momentMonth")(_vm.dateEnd)) +
                            " 月は " +
                            _vm._s(_vm._f("momentWeekStart")(_vm.dateEnd)) +
                            " までのデータです。"
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "a-button",
                {
                  class: _vm.$style.closeBtn,
                  on: { click: _vm.modalCancelRate }
                },
                [_vm._v("\n        閉じる\n      ")]
              )
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                title: _vm.rateTitle,
                visible: _vm.visibleCount,
                wrapClassName: _vm.$style.countModal,
                footer: null
              },
              on: { cancel: _vm.modalCancelCount }
            },
            [
              _c("div", [
                _vm.displayDateData[0]
                  ? _c("p", { staticStyle: { "margin-bottom": "2px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("momentYear")(_vm.displayDateData[0].date)
                        ) + " 年"
                      )
                    ])
                  : _vm._e(),
                _c(
                  "table",
                  {
                    staticClass: "table table-borderless",
                    class: _vm.$style.newTable
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("日付")]),
                        _c("th", [_vm._v(_vm._s(_vm.regulationName))])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.displayDateData, function(t, i) {
                        return _c(
                          "tr",
                          { key: i, class: _vm.$style["stripe" + (i % 2)] },
                          [
                            _vm.dateXAxis == "month"
                              ? _c("td", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm._f("momentMonth")(t.date)) +
                                      " 月\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm.dateXAxis == "week"
                              ? _c("td", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("momentWeekStart")(t.date)
                                      ) +
                                      " 〜 " +
                                      _vm._s(_vm._f("momentWeekEnd")(t.date)) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm.dateXAxis == "day"
                              ? _c("td", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm._f("momentMonth")(t.date)) +
                                      "月 " +
                                      _vm._s(_vm._f("momentDay")(t.date)) +
                                      " 日\n            "
                                  )
                                ])
                              : _vm._e(),
                            _c("td", [_vm._v(_vm._s(t.violationNum))])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm.dateXAxis == "month"
                  ? _c("div", [
                      _c(
                        "p",
                        { staticClass: "mb-0", class: _vm.$style.monthTips },
                        [
                          _vm._v(
                            "※ " +
                              _vm._s(_vm._f("momentMonth")(_vm.dateStart)) +
                              " 月は " +
                              _vm._s(_vm._f("momentWeekStart")(_vm.dateStart)) +
                              " からのデータです。"
                          )
                        ]
                      ),
                      _c("p", { class: _vm.$style.monthTips }, [
                        _vm._v(
                          "※ " +
                            _vm._s(_vm._f("momentMonth")(_vm.dateEnd)) +
                            " 月は " +
                            _vm._s(_vm._f("momentWeekStart")(_vm.dateEnd)) +
                            " までのデータです。"
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "a-button",
                {
                  class: _vm.$style.closeBtn,
                  on: { click: _vm.modalCancelCount }
                },
                [_vm._v("\n      閉じる\n    ")]
              )
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                title: "集計情報",
                visible: _vm.visibleDetailReport,
                wrapClassName: _vm.$style.statisticsModal,
                footer: null,
                width: "90%"
              },
              on: { cancel: _vm.modalCancelDetailReport }
            },
            [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "text-right no-print",
                    staticStyle: {
                      "margin-right": "20px",
                      "margin-top": "20px"
                    }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        class: _vm.$style.filledBtn,
                        attrs: { type: "primary" },
                        on: { click: _vm.print }
                      },
                      [_vm._v("印刷する")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { class: _vm.$style.statisticsFrame },
                  [
                    _c(
                      "h3",
                      {
                        staticClass: "text-center",
                        class: _vm.$style.statisticsTitle
                      },
                      [_vm._v("集計情報")]
                    ),
                    _c("div", { class: _vm.$style.filterInfoArea }, [
                      _c("div", { class: _vm.$style.leftInfo }, [
                        _c("div", [
                          _c("span", { class: _vm.$style.infoLabel }, [
                            _vm._v("対象支店")
                          ]),
                          _c("span", { class: _vm.$style.infoValue }, [
                            _vm._v(
                              _vm._s(
                                _vm.targetBranchSelected === "0"
                                  ? "全て"
                                  : _vm.targetBranchOptions.find(function(e) {
                                      return e.id === _vm.targetBranchSelected
                                    }).name
                              )
                            )
                          ])
                        ]),
                        _c("div", [
                          _c("span", { class: _vm.$style.infoLabel }, [
                            _vm._v("データの単位")
                          ]),
                          _c("span", { class: _vm.$style.infoValue }, [
                            _vm._v(
                              _vm._s(
                                _vm.target === "user" ? "ユーザー" : "車両"
                              )
                            )
                          ])
                        ]),
                        _c("div", [
                          _c("span", { class: _vm.$style.infoLabel }, [
                            _vm._v("閲覧対象")
                          ]),
                          _c("span", { class: _vm.$style.infoValue }, [
                            _vm._v(
                              _vm._s(
                                _vm.targetSelected === "0"
                                  ? "全て"
                                  : _vm.targetOptions.find(function(e) {
                                      return e.id === _vm.targetSelected
                                    }).name
                              )
                            )
                          ])
                        ])
                      ]),
                      _c("div", { class: _vm.$style.rightInfo }, [
                        _c("div", [
                          _c("span", { class: _vm.$style.infoLabel }, [
                            _vm._v("対象期間")
                          ]),
                          _c("span", { class: _vm.$style.infoValue }, [
                            _vm._v(
                              _vm._s(_vm._f("momentDate")(_vm.dateStart)) +
                                " 〜"
                            ),
                            _c("br", { staticStyle: { display: "none" } }),
                            _vm._v(
                              " " + _vm._s(_vm._f("momentDate")(_vm.dateEnd))
                            )
                          ])
                        ]),
                        _c("div", [
                          _c("span", { class: _vm.$style.infoLabel }, [
                            _vm._v("総運転時間")
                          ]),
                          _c("span", { class: _vm.$style.infoValue }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("secondToTime")(_vm.totalDrivingTime)
                              )
                            )
                          ])
                        ]),
                        _c("div", [
                          _c("span", { class: _vm.$style.infoLabel }, [
                            _vm._v("総走行距離")
                          ]),
                          _c("span", { class: _vm.$style.infoValue }, [
                            _vm._v(
                              _vm._s((_vm.totalDistance / 1000).toFixed(3)) +
                                " km"
                            )
                          ])
                        ])
                      ])
                    ]),
                    _c("h4", [_vm._v("違反情報")]),
                    _c("div", { class: _vm.$style.percentArea }, [
                      _c("div", { class: _vm.$style.percentContainer }, [
                        _c("span", { class: _vm.$style.percentLabel }, [
                          _vm._v("遵守率:")
                        ]),
                        _c("span", { class: _vm.$style.percentValue }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.displayComplianceRateTotal[2].toFixed(2)
                              ) +
                              " %"
                          )
                        ])
                      ]),
                      _c("div", { class: _vm.$style.numContainer }, [
                        _c("i", { staticClass: "fa fa-ban" }),
                        _c("span", { class: _vm.$style.numLabel }, [
                          _vm._v("違反件数:")
                        ]),
                        _c("span", { class: _vm.$style.numValue }, [
                          _vm._v(_vm._s(_vm.displayComplianceRateTotal[0]))
                        ]),
                        _c("i", {
                          staticClass: "fa fa-info-circle",
                          staticStyle: { "margin-left": "16px" }
                        }),
                        _c("span", { class: _vm.$style.numLabel }, [
                          _vm._v("規制件数:")
                        ]),
                        _c("span", { class: _vm.$style.numValue }, [
                          _vm._v(_vm._s(_vm.displayComplianceRateTotal[1]))
                        ])
                      ])
                    ]),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.reportViolationsColumns,
                        "data-source": _vm.reportViolations,
                        pagination: false,
                        size: "small",
                        rowClassName: function(r, i) {
                          return i % 2 === 0 ? "" : _vm.$style.stripe
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "num",
                            fn: function(num) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(num) + " 回")
                              ])
                            }
                          },
                          {
                            key: "numGeneral",
                            fn: function(numGeneral) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(numGeneral) + " 回")
                              ])
                            }
                          },
                          {
                            key: "numNarrow",
                            fn: function(numNarrow) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(numNarrow) + " 回")
                              ])
                            }
                          },
                          {
                            key: "perHour",
                            fn: function(perHour) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(perHour) + " 回")
                              ])
                            }
                          },
                          {
                            key: "perKm",
                            fn: function(perKm) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(perKm) + " 回")
                              ])
                            }
                          }
                        ],
                        null,
                        false,
                        1201247631
                      )
                    })
                  ],
                  1
                )
              ]),
              _c(
                "a-button",
                {
                  class: _vm.$style.closeBtn,
                  on: { click: _vm.modalCancelDetailReport }
                },
                [_vm._v("\n      閉じる\n    ")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c(
        "div",
        { staticClass: "utils__title", staticStyle: { "margin-left": "16px" } },
        [_c("strong", [_vm._v("違反種類別割合")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c(
        "div",
        { staticClass: "utils__title", staticStyle: { "margin-left": "16px" } },
        [_c("strong", [_vm._v("速度超過の内訳の割合")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header", staticStyle: { "margin-left": "16px" } },
      [
        _c("div", { staticClass: "utils__title" }, [
          _c("strong", [_vm._v("遵守率の推移")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c(
        "div",
        { staticClass: "utils__title", staticStyle: { "margin-left": "16px" } },
        [_c("strong", [_vm._v("時間帯別違反件数")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }